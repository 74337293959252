import React from "react";

import styles from "./styles.module.css";

export default function OrderNow() {
    return(
        <div>
                <a className={styles.orderNowButton} href="https://mac3.hrpos.heartland.us/order">Menu</a>
        </div>
    );
}
